import { useProjectsStore } from '@/stores/ProjectsStore.ts';

export const PROJECT_FRAMEWORKS = {
  MFF1: 'MFF-1',
} as const;

export const isProjectOwner = () => {
  const projectsStore = useProjectsStore();

  if (!projectsStore.project || !projectsStore.projectUsers) return false;

  return projectsStore.projectUsers.is_project_owner;
};
