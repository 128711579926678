import { defineStore } from 'pinia';
import { ref } from 'vue';

import type {
  AssetCardNature,
  AssetCardTypes,
  ControlAggregator,
  NotificationStatus,
  RiskDisplay,
  TestingTestStatus,
  VendorRiskLevel,
  VendorStatus,
  VendorType,
  Roles,
} from '@/client/api.ts';

import { Status } from '@/composables/utils.ts';

interface Date {
  from: string;
  to: string;
}

export const usePaginationStore = defineStore('pagination', () => {
  const nameFilter = ref('');
  const statusFilter = ref<null | NotificationStatus>(null);
  const frameworkQuery = ref<null | string>(null);
  const pageFilter = ref<number | null>(null);
  const tagFilter = ref<string | null>(null);
  const assignedToMe = ref<boolean>(false);
  const assignedToMeForReview = ref<boolean>(false);

  const evidenceControlsFilter = ref<ControlAggregator[] | null>(null);
  const evidenceRisksFilter = ref<RiskDisplay[] | null>(null);
  const evidenceTypeFilter = ref<string | null>(null);
  const evidenceTimeFilter = ref<Date | null>(null);

  const assetTypeFilter = ref<AssetCardTypes | null>(null);
  const assetNatureFilter = ref<AssetCardNature | null>(null);

  const vendorTypeFilter = ref<VendorType | null>(null);
  const vendorStatusFilter = ref<VendorStatus | null>(null);
  const vendorRiskLevelFilter = ref<VendorRiskLevel | null>(null);

  const testResultFilter = ref<TestingTestStatus | null>(null);
  const testStatusFilter = ref<boolean | null>(null);

  const usersRolesFilter = ref<Roles[] | null>(null);

  const setNameFilter = (name: string) => {
    nameFilter.value = name;
  };

  const setFrameworkQuery = (framework: string | null) => {
    frameworkQuery.value = framework;
  };

  const setStatusFilter = (status: NotificationStatus | Status | null) => {
    statusFilter.value = status
      ? (status.toLocaleLowerCase().replaceAll(' ', '_') as NotificationStatus)
      : null;
  };

  const setAssignedToMe = (value: boolean) => {
    assignedToMe.value = value;
  };

  const setAssignedForReview = (value: boolean) => {
    assignedToMeForReview.value = value;
  };

  const setPage = (page: number | null) => {
    pageFilter.value = page;
  };

  const setTagFilter = (tags: string | null) => {
    tagFilter.value = tags;
  };

  const setEvidenceControlsFilter = (tags: ControlAggregator[] | null) => {
    evidenceControlsFilter.value = tags;
  };

  const setEvidenceRisksFilter = (tags: RiskDisplay[] | null) => {
    evidenceRisksFilter.value = tags;
  };

  const setEvidenceTypeFilter = (type: string | null) => {
    evidenceTypeFilter.value = type;
  };

  const setEvidenceTimeFilter = (date: Date | null) => {
    evidenceTimeFilter.value = date;
  };

  const setAssetTypeFilter = (type: AssetCardTypes | null) => {
    assetTypeFilter.value = type;
  };

  const setAssetNatureFilter = (type: AssetCardNature | null) => {
    assetNatureFilter.value = type;
  };

  const setVendorTypeFilter = (type: VendorType | null) => {
    vendorTypeFilter.value = type;
  };

  const setVendorStatusFilter = (type: VendorStatus | null) => {
    vendorStatusFilter.value = type;
  };

  const setVendorRiskLevelFilter = (type: VendorRiskLevel | null) => {
    vendorRiskLevelFilter.value = type;
  };

  const setTestResultFilter = (type: TestingTestStatus | null) => {
    testResultFilter.value = type;
  };

  const setTestStatusFilter = (status: boolean | null) => {
    testStatusFilter.value = status;
  };

  const setUsersRolesFilter = (roles: Roles[] | null) => {
    usersRolesFilter.value = roles;
  };

  return {
    nameFilter,
    setNameFilter,
    frameworkQuery,
    pageFilter,
    assignedToMe,
    setFrameworkQuery,
    statusFilter,
    setStatusFilter,
    setPage,
    setAssignedToMe,
    tagFilter,
    setTagFilter,
    setEvidenceControlsFilter,
    evidenceControlsFilter,
    setEvidenceRisksFilter,
    evidenceRisksFilter,
    setEvidenceTypeFilter,
    evidenceTypeFilter,
    setEvidenceTimeFilter,
    evidenceTimeFilter,
    setAssignedForReview,
    assignedToMeForReview,
    setAssetTypeFilter,
    setAssetNatureFilter,
    assetTypeFilter,
    assetNatureFilter,
    setVendorTypeFilter,
    vendorTypeFilter,
    setVendorStatusFilter,
    vendorStatusFilter,
    setVendorRiskLevelFilter,
    vendorRiskLevelFilter,
    setTestResultFilter,
    testResultFilter,
    setTestStatusFilter,
    testStatusFilter,
    setUsersRolesFilter,
    usersRolesFilter,
  };
});
