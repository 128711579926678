import * as Sentry from '@sentry/vue';
import { useAuthStore } from '@/stores/AuthStore.ts';

export const captureException = function (
  error: unknown,
  { message, data = {} }: { message: string; data?: object },
) {
  const authStore = useAuthStore();
  const user = authStore.user;
  const organization = user
    ? { organization_id: user.organization.id, organization_name: user.organization.name }
    : {};

  Sentry.addBreadcrumb({ message, data: { ...organization, ...data } });

  Sentry.captureException(error);
};
