import { useGlobalStore } from '@/stores/GlobalStore.ts';
import { computed, ref } from 'vue';
import DOMPurify from 'dompurify';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

import { TestingTestStatus, type UserInviteResponse } from '@/client/api.ts';

export const MAX_NAME_SIZE = 50;
export const MAX_DESCRIPTION_SIZE = 250;
export const MAX_FILE_SIZE = 104857600; // 100MB
export const CURRENT_YEAR = computed(() => new Date().getFullYear());

export const CONCEPT = {
  RISK: 'Risk',
  CONTROL: 'Control',
  EVIDENCE: 'Evidence',
  ASSET: 'Asset',
} as const;

export const Status = {
  ALL: 'all',
  IN_REVIEW: 'in_review',
  COMPLETED: 'completed',
  IN_PROGRESS: 'in_progress',
  OUT_OF_SCOPE: 'out_of_scope',
} as const;

export const STATUS_LABEL = {
  ALL: 'All',
  IN_REVIEW: 'In Review',
  COMPLETED: 'Completed',
  IN_PROGRESS: 'In Progress',
  OUT_OF_SCOPE: 'Out Of Scope',
} as const;

export const TESTING_STATUS_LABEL = {
  PASSED: 'Passed',
  FAILED: 'Failed',
  ERROR: 'Error',
} as const;

export const TESTING_TEST_STATUS = (status: string) => {
  return status === TestingTestStatus.Passed
    ? TestingTestStatus.Passed
    : status === TestingTestStatus.Failed
      ? TestingTestStatus.Failed
      : TestingTestStatus.Error;
};

export type Status = (typeof Status)[keyof typeof Status];

export const statusIcon = (value: Status) => {
  return value === Status.IN_REVIEW
    ? 'ready'
    : value === Status.COMPLETED
      ? 'check_circle'
      : value === Status.IN_PROGRESS
        ? 'in_progress'
        : 'out_of_scope';
};

export const formatDate = (date: string, hoursMinutes: boolean = true) => {
  return hoursMinutes
    ? dayjs.utc(date).local().format('DD-MM-YYYY HH:mm')
    : dayjs.utc(date).local().format('DD-MM-YYYY');
};

export const blobImage = async (url: string) => {
  const res = await fetch(url);
  const blob = await res.blob();
  const imageBlob = new Blob([blob], { type: 'image/svg+xml' });
  return imageBlob;
};

export const ItemBorder = (status: Status) => {
  return status === Status.COMPLETED
    ? '#72A6E6'
    : status === Status.IN_REVIEW
      ? '#F8A07D'
      : status === Status.IN_PROGRESS
        ? '#8CA3AD'
        : '#76919E';
};

interface RejectedEntry {
  file: File;
  failedPropValidation: string;
}

export const FileRejected = (rejectedEntries: RejectedEntry[]) => {
  const store = useGlobalStore();
  const rejectedItems = rejectedEntries;
  const overSizedItems = ref<RejectedEntry[]>([]);

  rejectedItems.forEach((item) => {
    if (item.failedPropValidation === 'max-file-size') {
      overSizedItems.value.push(item);
    }
  });

  const overSizedItemsNames = overSizedItems.value.map((item) => item.file.name).join(', ');

  if (overSizedItemsNames.length > 0) {
    store.$q.notify({
      color: 'red',
      textColor: 'white',
      icon: 'error',
      message: `Maximum file size is ${BytesToMb(MAX_FILE_SIZE)}MB. Failed to upload files: ${overSizedItemsNames}`,
    });
  }
};

export function BytesToMb(bytes: number): number {
  return Number((bytes / (1024 * 1024)).toFixed(0));
}

export function FormatFileSize(input: string | number): string {
  let bytes: number;

  if (typeof input === 'string') {
    bytes = parseInt(input, 10);
  } else {
    bytes = input;
  }

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
}

export function FormatNumber(num: number) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
  }
  return num.toString();
}

export function ValidateURL(url: string) {
  if (!url) return true;
  try {
    const sanitizedURL = DOMPurify.sanitize(url);
    const parsedURL = new URL(sanitizedURL);
    const regex =
      /^(https?:\/\/)[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;
    return regex.test(parsedURL.href);
  } catch (_) {
    return false;
  }
}

export function lastInvite(invites: UserInviteResponse[]) {
  const sortedInvites = invites.sort((a, b) => {
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  });

  const lastInvite = sortedInvites[0];

  return lastInvite;
}
