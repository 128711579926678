<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useQuasar } from 'quasar';

import { useAuth } from '@/composables/Auth.ts';
import { captureException } from '@/composables/Sentry.ts';

import { useAuthStore } from '@/stores/AuthStore.ts';
import { useConfigStore } from '@/stores/ConfigStore.ts';
import { useNotificationStore } from '@/stores/NotificationStore.ts';
import { useStylesStore } from '@/stores/StylesStore.ts';

import Drawer from '@/components/DefaultLayout/DefaultDrawer.vue';
import DrawerRight from '@/components/DefaultLayout/DefaultDrawerRight.vue';
import Footer from '@/components/DefaultLayout/DefaultFooter.vue';
import Header from '@/components/DefaultLayout/DefaultHeader.vue';

const $q = useQuasar();
const store = useAuthStore();
const configStore = useConfigStore();
const stylesStore = useStylesStore();
const notificationStore = useNotificationStore();
const { refreshToken } = useAuth();
const ctrl = new AbortController();

const drawerOpen = ref(false);

function openDrawer() {
  drawerOpen.value = true;
}

function closeDrawer() {
  drawerOpen.value = false;
}

async function startStream() {
  const token = localStorage.getItem('access_token');

  if (!token) return;

  await fetchEventSource(`${import.meta.env.VITE_API_URL}/api/notifications/unread/stream/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    onmessage(ev) {
      const parseData = JSON.parse(ev.data);

      if (parseData.type === 'unread_notifications') {
        notificationStore.setNewNotifications(parseData.count);
      }
    },
    signal: ctrl.signal,
    onerror(e) {
      ctrl.abort();
      refreshToken().then(() => {
        startStream();
      });
      throw e;
    },
  });
}

onMounted(async () => {
  try {
    await store.getMe();
    await configStore.getOrganizationConfig();
    const user = localStorage.getItem('user');

    if (!user) return;

    store.user = JSON.parse(user);
    await store.userLastAccess();

    startStream();
  } catch (error) {
    captureException(error, { message: 'Component: DefaultLayout, Hook: onMounted' });
  }

  const darkMode = localStorage.getItem('darkMode');
  if (!darkMode) return;

  $q.dark.set(JSON.parse(darkMode));
});

onBeforeUnmount(() => {
  ctrl.abort();
});
</script>

<template>
  <q-layout view="lHr lpR lfr">
    <Header @open-drawer="openDrawer" />
    <Drawer />
    <q-page-container :style="{ paddingLeft: stylesStore.drawerOpened ? '260px' : '77px' }">
      <router-view />
    </q-page-container>
    <DrawerRight :drawer-open="drawerOpen" @close-drawer="closeDrawer" />
    <Footer />
  </q-layout>
</template>
