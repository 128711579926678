import { useAuthStore } from '@/stores/AuthStore.ts';
import { jwtDecode } from 'jwt-decode';
import { Configuration } from '../client/configuration.ts';

import router from '../router';
import type { BaseAPI } from '@/client/base.ts';
import type { AxiosInstance } from 'axios';

const isTokenExpired = (token: string) => {
  try {
    const decoded: { exp: number } = jwtDecode(token);
    return decoded.exp < Date.now() / 1000;
  } catch (e) {
    return true;
  }
};

async function initApi<T extends BaseAPI>(Api: {
  new (configuration?: Configuration, basePath?: string, axios?: AxiosInstance): T;
}): Promise<T | null> {
  const store = useAuthStore();
  let access_token = localStorage.getItem('access_token');
  if (!access_token || isTokenExpired(access_token)) {
    await store.getAccessTokenFromRefreshToken();
    access_token = localStorage.getItem('access_token');

    if (!access_token || isTokenExpired(access_token)) {
      router.push('/login');
      return null;
    }
  }
  const baseUrl = `${import.meta.env.VITE_API_URL}`;
  const config = new Configuration();
  config.basePath = baseUrl;
  config.accessToken = access_token;
  const newApi = new Api(config);
  return newApi;
}

export default initApi;
