<script setup lang="ts">
// import { useRouter } from 'vue-router';
import { ref, shallowRef, watch } from 'vue';

import { useAuthStore } from '@/stores/AuthStore.ts';
// import { vOnClickOutside } from '@vueuse/components';

const props = defineProps({
  drawerOpen: {
    type: Boolean,
    default: false,
  },
});

// const router = useRouter();
const authStore = useAuthStore();
const emit = defineEmits(['closeDrawer']);
const rightDrawerOpen = ref(false);

const drawerTopIcons = shallowRef([
  {
    name: 'person',
    text: 'Your profile',
  },
]);
const drawerMidIcons = shallowRef([
  {
    name: 'settings',
    text: 'Settings',
  },
]);

function closeDrawer() {
  emit('closeDrawer');
}

function userInitials() {
  if (!authStore.user) return;
  return (
    authStore.user.firstname.charAt(0).toUpperCase() +
    authStore.user.lastname.charAt(0).toUpperCase()
  );
}

function logout() {
  console.log('ERROR NOT IMPLEMENTED');
}

watch(
  () => props.drawerOpen,
  (newV) => {
    rightDrawerOpen.value = newV;
  },
);
</script>

<template>
  <q-drawer v-model="rightDrawerOpen" :width="220" :breakpoint="500" overlay bordered side="right">
    <q-scroll-area class="fit" :horizontal-thumb-style="{ opacity: '0' }">
      <div v-if="authStore.user" class="user row items-center q-mb-lg">
        <q-avatar class="q-mr-md col-1">
          <span>{{ userInitials() }}</span>
        </q-avatar>
        <span class="col-8">{{ authStore.user.firstname + ' ' + authStore.user.lastname }}</span>
      </div>
      <q-list padding>
        <q-item v-for="icon in drawerTopIcons" :key="icon.name" v-ripple clickable>
          <div class="sections__container row full-width">
            <q-item-section class="col-1" avatar>
              <q-btn flat :icon="icon.name"></q-btn>
            </q-item-section>

            <q-item-section class="col-6"> {{ icon.text }} </q-item-section>
          </div>
        </q-item>
        <hr />
        <q-item v-for="icon in drawerMidIcons" :key="icon.name" v-ripple clickable>
          <div class="sections__container row full-width">
            <q-item-section class="col-1" avatar>
              <q-btn flat :icon="icon.name"></q-btn>
            </q-item-section>

            <q-item-section class="col-6"> {{ icon.text }} </q-item-section>
          </div>
        </q-item>
      </q-list>
    </q-scroll-area>
    <q-btn class="btn-logout" flat @click="logout">Sign Out</q-btn>
    <q-btn class="btn-close" flat icon="close" @click="closeDrawer"></q-btn>
  </q-drawer>
</template>

<style lang="scss" scoped>
:deep(.q-drawer__content) {
  padding: 20px;
  background: linear-gradient(53deg, #00306b 14.85%, #04616b 88.36%);
  .text-black {
    color: unset !important;
  }
  button {
    padding: 0;
  }
  hr {
    margin-left: -10px;
    margin-right: -10px;
  }
  .user {
    padding: 0 5px;
    .q-avatar {
      width: 36px;
      height: 36px;
      cursor: pointer;
      background: $secondary-100;
      span {
        @include paragraph-02(400, $secondary-800);
      }
    }
  }
  .q-item {
    @include paragraph-01(400, $white);
    padding: 8px 5px !important;
    .sections__container {
      padding: 0 5px;
      border-radius: 5px;
      flex-wrap: nowrap;
    }
    .q-item__section {
      min-width: unset !important;
    }
    .q-item__section--side {
      padding-right: 0 !important;
    }
    .q-item__section--main {
      margin-left: 16px;
    }
    .q-focus-helper {
      background: transparent !important;
      opacity: 0 !important;
    }
  }

  .q-item:hover {
    .sections__container {
      background: rgba(255, 255, 255, 0.3);
    }
  }

  .toggle-button {
    position: absolute;
    top: 50%;
    right: -26.5px;
    padding: 6px;
    z-index: -1;
    .q-focus-helper {
      opacity: 1;
      background: linear-gradient(53deg, #00306b 14.85%, #04616b 88.36%) !important;
    }
  }
  .toggle-button:hover {
    .q-focus-helper {
      opacity: 0.7 !important;
    }
  }
  .btn-close {
    min-height: unset;
    position: absolute;
    top: 10px;
    right: 10px;
    i {
      font-size: 1.5em;
    }
    .q-focus-helper {
      display: none;
    }
    .q-btn__content {
      flex: unset;
      position: relative;
    }
    .q-btn__content::after {
      content: '';
      position: absolute;
      top: 0%;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      border-radius: inherit;
      opacity: 0;
      transition:
        background-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1),
        opacity 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
    }
    .q-btn__content:hover::after {
      background-color: #efefef;
      opacity: 0.15;
    }
  }
  .btn-logout {
    position: absolute;
    bottom: 20px;
    left: 20px;
    text-transform: unset;
    padding: 8px;
  }
}
</style>
