<script setup lang="ts">
import { QDrawer, useQuasar } from 'quasar';
import { onMounted, ref, watch, shallowRef } from 'vue';
import { useRoute } from 'vue-router';

import { useAuthStore } from '@/stores/AuthStore.ts';
import { useConfigStore } from '@/stores/ConfigStore.ts';
import { useStylesStore } from '@/stores/StylesStore.ts';

import Icon from '@/components/atoms/Icon.vue';
import DProfile from '@/components/dialogs/DProfile.vue';

import { useAuth, isAdmin } from '@/composables/Auth.ts';
import { isDev } from '@/composables/Env.ts';

const $q = useQuasar();
const route = useRoute();
// const router = useRouter();
const showDialog = ref(false);
const showToggle = ref(false);
const authStore = useAuthStore();
const stylesStore = useStylesStore();
const configStore = useConfigStore();
const { logout } = useAuth();
let showTimeout = ref<undefined | ReturnType<typeof setTimeout> | undefined>(undefined);
const drawer = ref<QDrawer | HTMLElement | null>(null);
const toggleElement = ref<HTMLElement | null>(null);

const miniState = ref(true);
const leftDrawerOpen = ref(true);
let pathActive = ref(route.path);

const drawerTopIcons = shallowRef([
  {
    name: 'icon-folder_copy',
    text: 'Projects',
    to: '/projects',
  },
  {
    name: 'account_tree',
    text: 'Organization',
    to: isAdmin() ? '/organization/users' : '/organization/templates',
  },
  {
    name: 'storefront',
    text: 'Vendors',
    to: '/vendors',
  },
]);

const drawerBotIcons = shallowRef([
  {
    name: 'icon-support',
    text: 'Support',
    to: null,
    href: isDev ? 'https://docs.dev.modulos.ai' : 'https://docs.modulos.ai',
  },
]);

// function lightMode() {
//   $q.dark.set(false);
//   localStorage.setItem('darkMode', JSON.stringify(false));
// }

// function darkMode() {
//   $q.dark.set(true);
//   localStorage.setItem('darkMode', JSON.stringify(true));
// }

// function toggleMode() {
//   $q.dark.set(!$q.dark.isActive);
//   localStorage.setItem('darkMode', JSON.stringify($q.dark.isActive));

//   drawerBotIcons.value[0].name = $q.dark.isActive ? 'icon-dark_mode' : 'icon-light_mode';
//   drawerBotIcons.value[0].text = $q.dark.isActive ? 'Dark Mode' : 'Light Mode';
// }

function openDrawer() {
  miniState.value = false;
  stylesStore.toggleDrawer(true);
}

function closeDrawer() {
  miniState.value = true;
  showToggle.value = false;
  stylesStore.toggleDrawer(false);
}

function userInitials() {
  if (!authStore.user) return;
  return (
    authStore.user.firstname.charAt(0).toUpperCase() +
    authStore.user.lastname.charAt(0).toUpperCase()
  );
}

function delayShow(event: Event) {
  showTimeout.value = setTimeout(() => {
    const sidebarRef = drawer.value as QDrawer;

    if (!sidebarRef) return;

    if (sidebarRef.$el.contains(event.target as HTMLElement)) {
      showToggle.value = true;
    }
  }, 500);
}

function handleMouseOut(event: Event) {
  const sidebarRef = drawer.value as QDrawer;
  const toggleRef = toggleElement.value;
  if (!sidebarRef || !toggleRef) return;

  const sidebarRect = sidebarRef.$el.getBoundingClientRect();
  const mouseEvent = event as MouseEvent;

  if (
    mouseEvent.clientX >= sidebarRect.left &&
    mouseEvent.clientX <= sidebarRect.right &&
    mouseEvent.clientY >= sidebarRect.top &&
    !toggleRef.contains(event.target as HTMLElement) &&
    miniState.value
  ) {
    showToggle.value = false;
    clearTimeout(showTimeout.value);
  }
}

watch(
  route,
  (newValue) => {
    if (newValue.path.includes('project-detail')) {
      pathActive.value = '/projects';
      return;
    } else if (newValue.path.includes('organization')) {
      pathActive.value = '/organization';
      return;
    } else if (newValue.path.includes('vendors')) {
      pathActive.value = '/vendors';
      return;
    }
    pathActive.value = newValue.path;
  },
  {
    immediate: true,
  },
);

watch(
  () => stylesStore.drawerOpened,
  (newValue) => {
    miniState.value = !newValue;
  },
);

onMounted(async () => {
  stylesStore.toggleDrawer(false);
});
</script>

<template>
  <q-drawer
    ref="drawer"
    v-model="leftDrawerOpen"
    show-if-above
    :mini="miniState"
    mini-to-overlay
    :width="260"
    :mini-width="77"
    :breakpoint="500"
    side="left"
    @mouseover="delayShow"
    @mouseleave="handleMouseOut"
  >
    <q-scroll-area class="fit" :horizontal-thumb-style="{ opacity: '0' }">
      <q-list padding class="column">
        <q-item id="logo" v-ripple to="/dashboard" clickable class="q-mb-md">
          <div class="sections__container row full-width">
            <q-item-section avatar>
              <Icon icon-name="modulos-logo" icon-folder="drawer" icon-size="26px" />
            </q-item-section>

            <q-item-section :style="{ marginLeft: '16px' }">
              <Icon icon-name="modulos-logo-text" icon-folder="drawer" />
            </q-item-section>
          </div>
        </q-item>
        <div class="project-icons__container">
          <div v-for="icon in drawerTopIcons" :key="icon.name">
            <q-item
              v-if="icon.name !== 'storefront'"
              v-ripple
              :to="icon.to ? icon.to : null"
              :active="icon.to === pathActive"
              clickable
              @click="pathActive = icon.to ? icon.to : ''"
            >
              <div class="sections__container row full-width justify-center">
                <q-item-section v-if="icon.to" avatar>
                  <Icon :icon-name="icon.name" icon-folder="drawer"> </Icon>
                </q-item-section>
                <q-item-section :style="{ marginLeft: '16px' }">
                  <div class="row items-center">
                    <span>{{ icon.text }}</span>
                  </div>
                </q-item-section>
                <q-tooltip anchor="center right" self="center left" :offset="[5, 5]">
                  {{ icon.text }}
                </q-tooltip>
              </div>
            </q-item>
            <q-item
              v-if="icon.name === 'storefront' && configStore.vendorsEnabled"
              v-ripple
              :to="icon.to ? icon.to : null"
              :active="icon.to === pathActive"
              clickable
              @click="pathActive = icon.to ? icon.to : ''"
            >
              <div class="sections__container row full-width justify-center">
                <q-item-section v-if="icon.to" avatar>
                  <Icon :icon-name="icon.name" icon-folder="drawer"> </Icon>
                </q-item-section>
                <q-item-section :style="{ marginLeft: '16px' }">
                  <div class="row items-center">
                    <span>{{ icon.text }}</span>
                  </div>
                </q-item-section>
                <q-tooltip anchor="center right" self="center left" :offset="[5, 5]">
                  {{ icon.text }}
                </q-tooltip>
              </div>
            </q-item>
          </div>
        </div>
        <div class="settings-icons__container">
          <q-item
            v-for="icon in drawerBotIcons"
            :key="icon.name"
            v-ripple
            :to="icon.to ? icon.to : null"
            :href="icon.href ? icon.href : ''"
            target="_blank"
            :active="icon.to === pathActive"
            clickable
            @click="pathActive = icon.to ? icon.to : ''"
          >
            <div class="sections__container row full-width justify-center">
              <q-item-section v-if="icon.to || icon.href" avatar>
                <Icon :icon-name="icon.name" icon-folder="drawer" />
              </q-item-section>
              <q-item-section :style="{ marginLeft: '16px' }">
                <div class="row items-center">
                  <span>{{ icon.text }}</span>
                </div>
              </q-item-section>
              <q-tooltip anchor="center right" self="center left" :offset="[5, 5]" :delay="300">
                {{ icon.text }}
              </q-tooltip>
            </div>
          </q-item>
        </div>
        <!-- <div v-if="!miniState" class="bottom-icons__container">
          <div class="actions__container row no-wrap q-pa-xs">
            <q-btn label="Light" :class="$q.dark.isActive ? '' : 'active'" @click="lightMode()">
              <slot> <Icon icon-name="icon-light_mode" icon-folder="drawer" /> </slot
            ></q-btn>
            <q-btn label="Dark" :class="$q.dark.isActive ? 'active' : ''" @click="darkMode()">
              <slot> <Icon icon-name="icon-dark_mode" icon-folder="drawer" /> </slot
            ></q-btn>
          </div>
        </div> -->
        <q-item v-ripple class="drawer-profile" clickable @click="showDialog = true">
          <div class="sections__container row full-width justify-center">
            <q-item-section avatar>
              <span>{{ userInitials() }}</span>
            </q-item-section>

            <q-item-section :style="{ marginLeft: '16px' }"> User Profile </q-item-section>
          </div>
        </q-item>
      </q-list>
    </q-scroll-area>
    <div
      v-if="miniState"
      ref="toggleElement"
      class="sidebar-toggle row items-center justify-center"
      :class="[showToggle ? 'show-toggle' : '']"
      @click="openDrawer"
    >
      <Icon v-if="showToggle" icon-name="double_arrow" icon-folder="drawer" />
    </div>
    <div
      v-else
      ref="toggleElement"
      class="sidebar-toggle row items-center justify-center"
      :class="[showToggle ? 'show-toggle' : '']"
      @click="closeDrawer"
    >
      <Icon v-if="showToggle" icon-name="double_arrow-left" icon-folder="drawer" />
    </div>
  </q-drawer>
  <DProfile
    v-if="showDialog && authStore.user"
    :left="miniState ? '90px' : '275px'"
    @hide="showDialog = false"
  >
    <template #top>
      <div class="row justify-between">
        <q-avatar class="q-mr-md">
          <!-- <Icon icon-name="profile-image" icon-folder="profile" /> -->
          <div v-if="authStore.user" class="user flex items-center">
            <q-avatar>
              <span>{{ userInitials() }}</span>
            </q-avatar>
          </div>
        </q-avatar>
        <div class="user-info column justify-center">
          <span class="user-name">{{
            authStore.user?.firstname + ' ' + authStore.user?.lastname
          }}</span>
          <span class="user-email">{{ authStore.user?.email }}</span>
        </div>
        <q-btn flat>
          <slot>
            <Icon
              v-if="$q.dark.isActive"
              :icon-name="'logout-white'"
              icon-folder="header"
              icon-size="24px"
              @click="logout"
            />
            <Icon
              v-else
              :icon-name="'logout'"
              icon-folder="header"
              icon-size="24px"
              @click="logout"
            />
          </slot>
        </q-btn>
      </div>
    </template>
  </DProfile>
</template>

<style lang="scss" scoped>
:deep(aside) {
  box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.25);
  .q-drawer__content {
    background: linear-gradient(9deg, #00306b -10.06%, #04616b 69.94%);
    .text-black {
      color: unset !important;
    }

    .q-list {
      height: calc(100vh - 210px);
      padding: 0 !important;
    }

    .q-item {
      @include paragraph-01(400, $white);
      line-height: 20px;
      padding: 8px 12px !important;
      .sections__container {
        padding: 12px 13px;
        border-radius: 5px;
        flex-wrap: nowrap;
        position: relative;

        .q-item__section div svg:hover {
          cursor: pointer;
        }
        .q-item__section div a:hover {
          cursor: pointer;
          background: rgba(255, 255, 255, 0.3);
        }
        .sub-list {
          ul {
            max-height: 165px;
            flex-wrap: nowrap;
            overflow-y: scroll;
            -ms-overflow-style: none; /* for Internet Explorer, Edge */
            scrollbar-width: none; /* for Firefox */
            transition: all 0.3s ease;
            a {
              @include paragraph-01(400, $white);
              text-decoration: none;
              border-radius: 5px;
            }
          }
          ul::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
          }
        }
      }
      .q-item__section {
        min-width: unset !important;
      }
      .q-item__section--side {
        padding-right: 0 !important;
      }
      .q-focus-helper {
        background: transparent !important;
        opacity: 0 !important;
      }
      .q-ripple {
        display: none;
      }
    }
    .no-cursor {
      cursor: default !important;
    }
    .q-item--active,
    .q-item.q-router-link--active {
      .sections__container {
        background: rgba(255, 255, 255, 0.3);
      }
    }

    .q-item:hover {
      .sections__container {
        background: rgba(255, 255, 255, 0.3);
      }
      .no-hover {
        background: transparent;
        cursor: default;
      }
    }
    .q-item:active {
      .no-hover {
        background: transparent;
        cursor: default;
      }
    }

    #logo {
      padding: 8.5px 12px !important;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      .sections__container {
        background: transparent !important;
        .q-item__section {
          justify-content: flex-start !important;
        }
      }
    }
    .settings-icons__container {
      width: 100%;
    }
    .bottom-icons__container {
      width: 100%;
      position: absolute;
      bottom: 150px;
      left: 50%;
      transform: translate(-50%, 0);
      .q-item {
        padding: 0 !important;
        min-height: unset;
        justify-content: center !important;
      }
      .sections__container {
        padding: 5px;
      }
      .mode {
        background: rgba(255, 255, 255, 0.05);
      }
      hr {
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
      }
      .actions__container {
        justify-content: center;
        button::before {
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15) !important;
        }
        .q-btn__content {
          flex-direction: row-reverse;
          flex-wrap: nowrap;
          align-items: center;
          span {
            text-wrap: nowrap;
          }
          slot-icon {
            margin-right: 8px;
            line-height: 0;
          }
        }
        .active {
          background: rgba(255, 255, 255, 0.3);
        }
      }
    }
    .drawer-profile {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      border-top: 1px solid rgba(255, 255, 255, 0.15);

      .sections__container {
        padding: 12px 13px;
        span {
          @include caption(400, $white);
          height: 25px;
          width: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: $secondary-500;
          border-radius: 50%;
        }
      }
    }
    .sidebar-toggle {
      width: 24.6px;
      height: 32.6px;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 50%;
      background: $secondary-700;
      border-radius: 3px;
      z-index: -1;
      transition: all 0.5s ease;
    }
    .show-toggle {
      right: -23px;
    }
  }
}

.q-drawer--mini {
  .q-item {
    text-align: unset !important;
    justify-content: unset !important;
    min-width: unset !important;
  }
}
</style>
